@import "../../variables.scss";

.active {
  border-bottom: 1px solid $highlighted-text-color-one;
}

.navigationbar {
  width: 100%;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  &__inner {
    margin: 0px 10px;
    margin-left: 0px;
    margin-top: -10px;
    padding-bottom: 10px;
    width: 1000px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    @media (max-width: 1050px) {
      width: 95vw;
    }
    @media (max-width: 750px) {
      flex-direction: column;
    }
    &__left {
      margin-top: 10px;
      padding: 0px 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 32px;
      font-weight: 700;
      border-bottom: none;
      &__link {
        margin: 5px 5px;
        border-radius: $border-radius;
        box-shadow: $box-shadow-1, $box-shadow-2;
        padding: 5px 12px;
        text-decoration: none;
        text-transform: uppercase;
        color: $secondary-color;
        outline: 0;
        &:hover {
          box-shadow: $box-shadow-inset1, $box-shadow-inset2;
        }
      }
    }
    &__right {
      margin-top: 10px;
      text-decoration: none;
      color: $secondary-color;
      margin-left: 5px;

      &__link {
        font-size: 13px;
        margin: 0px 0px 2px 6px;
        padding: 6px 7px;
        padding-right: 10px;
        border-radius: $border-radius;
        box-shadow: $box-shadow-1, $box-shadow-2;
        text-decoration: none;
        color: $secondary-color;
        outline: 0;
        @media ($responsive-breakpoint-two) {
          font-size: 12px;
          margin: 0;
          padding-right: 10px;
        }
        @media ($responsive-breakpoint-four) {
          padding-right: 5px;
        }

        &:hover {
          box-shadow: $box-shadow-inset1, $box-shadow-inset2;
        }
        &__icon {
          font-size: 12px;
          margin: 0px 0px 2px 4px;
          padding: 4px;
          padding-left: 0px;
          margin-bottom: -6px;
        }
      }
    }
  }
}
