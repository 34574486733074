$background-color: #1a1b1e;
$div-background-color: #1a1b1e;

$border-radius: 4px;
$box-shadow-inset1: inset 5px 5px 10px #141517;
$box-shadow-inset2: inset -5px -5px 10px #202125;
$box-shadow-1: 5px 5px 11px #151619;
$box-shadow-2: -5px -5px 11px #1f2023;

$primary-color: #282c34;
$secondary-color: #eeeeee;
$highlighted-text: -webkit-linear-gradient(225deg, #4cb8a4, #3cd3ad);
$highlighted-text-color-one: #4cb8a4;
$highlighted-text-color-two: #3cd3ad;

$text-color: #eeeeee;
$normal-text-color: #282c34;
$disabled-text-color: #282c34;
$highlighted-text-color: #282c34;

$main-container-width: "1000px";
$responsive-breakpoint-one: "max-width: 1050px";
$responsive-breakpoint-two: "max-width: 750px";
$responsive-breakpoint-three: "max-width: 500px";
$responsive-breakpoint-four: "max-width: 400px";
